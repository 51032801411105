import React from 'react';
import axios from 'axios';
import {Table, Progress, Button, Select} from 'antd';
import env from './env.json';
import logo from './assets/logo.png';
import './assets/leaderboard.css';

class Leaderboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getLeaderboard = async () => {
        const resp = await axios.get(`${env.BACKEND_URL}/leaderboard`);
        this.state && this.state.filteredNames && this.state.filteredNames.forEach(n => {
            resp.data.columns.splice(resp.data.columns.findIndex(c => c.dataIndex === n), 1);
            delete resp.data.names[n];
        });
        return this.setState(resp.data);
    };
    componentDidMount = async () => {
        await this.getLeaderboard();
        this.interval = setInterval(async () => await this.getLeaderboard(), 1000);
    }
    componentWillUnmount() {clearInterval(this.interval);}

    getQuestionMetrics = () => {
        const {dataSource} = this.state;
        if (!dataSource) return {}
        let questionsAnswered = 0;
        let questionsUnanswered = 0;
        dataSource.forEach(d => {
            d.Answer === '-' ? questionsUnanswered += 1 : questionsAnswered += 1
        })
        const totalQuestions = questionsUnanswered + questionsAnswered;
        const percentage = questionsUnanswered / totalQuestions * 100;
        return {questionsUnanswered, questionsAnswered, totalQuestions, percentage}
    }
    getPoints = () => {
        const {dataSource, names} = this.state;
        if (!dataSource || !names) return {}
        const points = Object.assign({}, names);
        dataSource.forEach(d => {
            Object.keys(names).forEach(n => {
                if (d[n] === d['Answer']) {
                    if (d['Answer'].includes('3pts')){
                        points[n] += 3
                    } else if (d['Answer'].includes('2pts')) {
                        points[n] += 2;
                    } else {
                        points[n] += 1;
                    }
                }
            })
        })

        return Object.entries(points).sort(([,a],[,b]) => b-a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    }
    renderPoints = (points, questionMetrics) => {
        const data = [];
        for (const [n, p] of Object.entries(points)) {
            data.push(
                <div className='points-row' key={n}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}><span>{n}</span><span style={{color: '#970747', marginRight: 5}}>{p}</span></div>
                    <Progress percent={Math.round(p / questionMetrics.questionsAnswered * 100)} status={'normal'} strokeColor='#970747' showInfo={false}/>
                </div>

            )
        }
        return data;
    }
    colorColumns = (columns) => {
        const coloredColumns = Object.assign([], columns);
        coloredColumns.forEach(c => {
            c.render = (text, record, index) => {
                return {
                    props: {style: { background: record['Answer'] === '-' || text === record['key'] ? 'inherit' : text === record['Answer']  ? "lightgreen" : "#e38ba9" }},
                    children: <div>{text}</div>
                };
            }
        })
        return coloredColumns;
    }

    render() {
        const {dataSource, columns, names} = this.state;
        const questionMetrics = this.getQuestionMetrics();
        const points = this.getPoints();
        return (
            <div className='leaderboard-container'>
                <div className='leaderboard-left-side'>
                    <img src={logo} className='logo-home' alt='logo-home' style={{marginBottom: 20}}/>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Button type="primary" size={'medium'} className='make-your-picks-button' onClick={() => window.open(`https://docs.google.com/forms/d/e/${env.FORM_ID}/viewform`, '_blank')}>Make your picks</Button>
                        <span className='button-breaker'/>
                        <Button type="primary" size={'medium'} className='make-your-picks-button' onClick={() => window.open(`https://docs.google.com/spreadsheets/d/${env.SPREADSHEET_ID}/edit#gid=1550689484`, '_blank')}>View responses</Button>
                    </div>
                    <Progress strokeColor='#970747' type="circle" percent={questionMetrics.percentage} format={() => <div>{questionMetrics.questionsUnanswered} <div>Left</div></div>}/>
                    <div className='score-container' style={{marginTop: 10}}>
                        {this.renderPoints(points, questionMetrics)}
                    </div>
                </div>
                <div className='leaderboard-right-side'>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%', marginBottom: 7}}
                        placeholder='Hide these names...'
                        onChange={(names) => this.setState({filteredNames: names})}
                        options={names ? Object.keys(names).map(n => ({label: n, value: n})) : []}
                    />
                    <Table dataSource={dataSource} columns={this.colorColumns(columns)} pagination={false} scroll={{ y: '100vh' }}/>
                </div>
            </div>
        );
    }

}

export default Leaderboard;