import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Leaderboard from './Leaderboard';
import './assets/index.css';

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/" component={Leaderboard}/>
        </Switch>
    </BrowserRouter>
  , document.getElementById('root')
);
